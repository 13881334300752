exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afastamento-de-pombos-tsx": () => import("./../../../src/pages/afastamento-de-pombos.tsx" /* webpackChunkName: "component---src-pages-afastamento-de-pombos-tsx" */),
  "component---src-pages-descupinizacao-tsx": () => import("./../../../src/pages/descupinizacao.tsx" /* webpackChunkName: "component---src-pages-descupinizacao-tsx" */),
  "component---src-pages-desinsetizacao-tsx": () => import("./../../../src/pages/desinsetizacao.tsx" /* webpackChunkName: "component---src-pages-desinsetizacao-tsx" */),
  "component---src-pages-desratizacao-tsx": () => import("./../../../src/pages/desratizacao.tsx" /* webpackChunkName: "component---src-pages-desratizacao-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-limpeza-de-reservatorios-e-caixas-dagua-tsx": () => import("./../../../src/pages/limpeza-de-reservatorios-e-caixas-dagua.tsx" /* webpackChunkName: "component---src-pages-limpeza-de-reservatorios-e-caixas-dagua-tsx" */),
  "component---src-pages-mosquitos-tsx": () => import("./../../../src/pages/mosquitos.tsx" /* webpackChunkName: "component---src-pages-mosquitos-tsx" */),
  "component---src-pages-sobre-a-insethelp-tsx": () => import("./../../../src/pages/sobre-a-insethelp.tsx" /* webpackChunkName: "component---src-pages-sobre-a-insethelp-tsx" */)
}

